.editor-wrapper {
  width: 100%;
  margin: 0 25%;
}

div.DraftEditor-root {
  border: 1px solid #000;
  background-color: beige;
  /* height: 200px; */

  overflow-y: auto;
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  height: 100%;
}
