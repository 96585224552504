.calendar-screen .ant-table-wrapper {
  flex: 1;
  width: 100%;
}
.calendar-screen .ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}
.calendar-screen .ant-pagination {
  margin-right: 16px !important;
}
.calendar-screen .ant-picker-calendar-mode-switch {
  display: none !important;
}
.calendar-screen .addNewButton {
  text-align: center;
}
.ant-modal-header .ant-picker {
  width: 90%;
}

.ant-modal-header .ant-picker input {
  font-size: 18px;
}
