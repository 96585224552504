.devotion_editor__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.devotion_editor_title {
  width: 140px;
}

.radio__container .free.ant-radio-button-wrapper-checked {
  background-color: #52c41a;
  color: white;
  border-color: #d9d9d9 !important;
}
.radio__container .premium.ant-radio-button-wrapper-checked {
  background-color: #ff4d4f;
  color: white;
  border-color: #d9d9d9 !important;
}

.devotion_editor__header input {
  width: 36px;
  text-align: center;
}


