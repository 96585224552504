.gg-dashboard .logout-button {
  float: right;
  margin-top: 15px;
}

.gg-dashboard .logo {
  margin-right: 24px;
}

.gg-dashboard .ant-menu-item-selected {
  background-color: #c0ad98 !important;
}

.gg-dashboard .ant-menu-item-selected span {
  color: black !important;
}

.gg-dashboard .ant-menu-item:hover {
  background-color: #c0ad98 !important;
}
